/**
 Shape & initial state of events
 */

import moment from 'moment';
import {formatDbDate} from '../../common/utils/date';
const EVENTS_FILTER_TYPE = 'EventsFilter';
const EVENT_POPOVER_TYPE = 'EventPopover';
const EVENT_CONTEXT_MENU_TYPE = 'EventContextMenu';

export const defaultStartDate = () => formatDbDate(moment().subtract(2, 'days'));
export const defaultEndDate = () => formatDbDate(moment().add(14, 'days'));

export const eventsFilter = {
    __typename: EVENTS_FILTER_TYPE,
    /**
     * Default timeline view is 2 days before & 2 weeks ahead
     */
    startDate: defaultStartDate(),
    endDate: defaultEndDate(),
    //filters remote events by channel
    bookingChannel: null
};

export const eventPopover = {
    __typename: EVENT_POPOVER_TYPE,
    //event associated with the bar being hovered, set here as JSON
    barData: null
};

export const eventContextMenu = {
    __typename: EVENT_CONTEXT_MENU_TYPE,
    barData: null
};

/**
 * EVENTS_FILTER_TYPE
 */
export const getEventsFilterShape = cacheKeys => {
    return {
        data: {
            eventsFilter: {
                __typename: EVENTS_FILTER_TYPE,
                ...cacheKeys
            }
        }
    }
};
/**
 EVENT_POPOVER_TYPE
*/
export const getEventPopoverShape = cacheKeys => {
    return {
        data: {
            eventPopover: {
                __typename: EVENT_POPOVER_TYPE,
                ...cacheKeys
            }
        }
    }
};

/**
 EVENT_CONTEXT_MENU_TYPE
*/
export const getEventContextMenuShape = cacheKeys => {
    return {
        data: {
            eventContextMenu: {
                __typename: EVENT_CONTEXT_MENU_TYPE,
                ...cacheKeys
            }
        }
    }
};