/**
 Cache structure for revenue events
 */
import {arrayToObject} from '../../../common/utils/state';

export const REVENUE_EVENT_TYPE = 'RevenueEventType';

/**
 * values match DOM ids & GraphQL fields
 */
export const REVENUE_EVENT_FIELDS = ['eventType', 'name', 'fromDate', 'toDate', 'defaultMultiplier', 'country', 'city', 'neighbourhood'];

export const revenueEvent = {
    __typename: REVENUE_EVENT_TYPE,
    id: null, //id is set on edit
    ...arrayToObject(REVENUE_EVENT_FIELDS)
};