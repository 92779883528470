/**
 Generic utility methods when working with cache, state or forms
*/

/**
 * Turns an array into an object, ['a', 'b'] => {a: null, b: null}
 * @param sourceArray
 * @param initKeysWith
 * @return {*}
 */
export const arrayToObject = (sourceArray, initKeysWith = null) => {
    return sourceArray.reduce((result, item) => {
        result[item] = initKeysWith;
        return result;
    }, {});
};
/**
 * A convenient utility to wait for set milliseconds.
 * This is handy when displaying sequential progress messages for operations that finish too quickly!
 * @param ms
 * @return {Promise<any>}
 */
export const waitFor = ms => {
    return new Promise(resolve => {
        setTimeout(() => {resolve(null)}, ms);
    });
};
/**
 * Converts "Hello World" to hello-world, handy for generating test ids from titles, etc.
 * @param text
 * @return {string}
 */
export const toSpinalCase = text => (text.toLowerCase().replace(/\s/g, '-'));
/**
 * Populates initial values for form state
 * @param {Array} fields list of fields names
 * @param {Object} state field-name to initial value, can be empty
 * @param {Object} override overrides `state` for present fields
 * @param defaultValue global default value if none is found in `state` or `override`
 * @param skipFields a list of fields names to skip if found
 */
export const setDefaultValues = (fields, state, override, defaultValue = '', skipFields = []) => {
    const allFields = {};
    for(let field of fields)
    {
        if(skipFields.includes(field))
        {
            continue;
        }
        let value = state[field];
        if(override[field] !== undefined)
        {
            value = override[field];
        }
        if(value === undefined || value === null)
        {
            value = defaultValue;
        }
        allFields[field] = value;
    }
    return allFields;
};
/**
 * Takes a validity object where each key resembles a field ID with value
 * indicating if field is valid or not
 */
export const validityObjContainsFalse = validity => {
    const objValues = Object.values(validity);
    for (const val of objValues) {
        if(typeof val !== 'boolean') {
            if(validityObjContainsFalse(val)) return true;
        }
        else if(val === false) {
            return true;
        }
    };
    return false;
};

/**
 * Takes an object where each key resembles a field ID with value
 * indicating if field is valid or not
 */
export const ObjContainsTrue = obj => {
    return Object.values(obj).some(value => !!value);
};

/**
 * @param obj "One-level" deep object to be validated
 * @param exclude Array of falsy values to be excluded from the validation
 * @param include Array of truthy values to be included in the validation
 * @returns true if the object have at least one falsy value.
 * @example console.log(ObjContainsFalsy({x: 0}, [0])) // false
 * console.log(ObjContainsFalsy({y: "foobar"}, [0])) // false
 * console.log({z: "foobar"}, ["foobar"]) // true
 */
export const objContainsFalsy = (obj, exclude = []) => {
    return Object.values(obj).some((value) => {
        if (exclude.includes(value)) return false;

        return !value;
    });
};