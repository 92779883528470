export const cityMapper = {
    Dubai: 'DXB',
    Montreal: 'MTL',
    Manama: 'BAH'
};

export const neighbourhoodMapper = {
    'Marina': 'MAR',
    'Palm Jumeirah': 'PJ',
    'Media City': 'MC',
    'Downtown': 'DOW',
    'Barsha Heights': 'TECOM',
    'Ville Marie': 'DOWNTOWN',
    'Mont-Royal': 'MOR',
    'Old Port': 'OLDPORT',
    'Jumeirah Village Circle': 'JVC',
    'Marassi Al Bahrain': 'MARASSI',
    'Kite Beach': 'KB'
};

export const bedroomsMapper = {
    '0' : 'Studio',
    '1' : '1BDR',
    '2' : '2BDR'
};

export const longTermLeaseStatuses = [
    'Legal',
    'Upcoming',
    'Expiring in 90 days',
    'Extension',
    'Current',
    'Completed'
];

export const leaseStatuses = [
    'Legal',
    'Expiring in 90 days',
    'Extension',
    'Occupied',
    'Vacant',
    'Vacant Booked'
];

export const tenantStatuses = [
    'Legal',
    'Expiring in 90 days',
    'Extension',
    'Upcoming',
    'Active',
    'Completed'
];

export const monthsMapper = {
    '0': 'Jan',
    '1': 'Feb',
    '2': 'Mar',
    '3': 'Apr',
    '4': 'May',
    '5': 'Jun',
    '6': 'Jul',
    '7': 'Aug',
    '8': 'Sep',
    '9': 'Oct',
    '10': 'Nov',
    '11': 'Dec'
};

export const propertyTypes = [
    'Furnished',
    'Unfurnished'
];

export const maintenanceCaseStatuses = [
    'pending',
    'flagged',
    'done'
];

export const visaStates = [
    'valid',
    'invalid'
];

export const tenantTypes = [
    'Person',
    'Company'
];

export const booleanValues = {
    'No': false,
    'Yes': true
};

export const addonServices = {
    'parking': 'Parking',
    'gas connection fees': 'Gas connection fees',
    'access cards': 'Access cards',
    'ejari fees': 'Ejari fees',
    'agent commission': 'Agent commission',
    'management services': 'Management services'
};

export const amenities = [
    'Private pool',
    'beachfront',
    'Beach',
    'Terrace',
    'Balcony',
];

export const PROPERTIES_FILTER_ROOMS_BEDS = 'PropertiesFilterForRoomsAndBeds';

export const PROPERTIES_FILTER_AVAILABILITY = 'PropertiesFilterForAvailability';

/**
 * An array for weekdays. start with Monday of 0 index to Sunday of 6 index
 *
 */
export const WEEK_DAYS = {
    monday: {
        short: 'Mon',
        long: 'monday'
    },
    tuesday: {
        short: 'Tue',
        long: 'tuesday'
    },
    wednesday: {
        short: 'Wed',
        long: 'wednesday'
    },
    thursday: {
        short: 'Thu',
        long: 'thursday'
    },
    friday: {
        short: 'Fri',
        long: 'friday'
    },
    saturday: {
        short: 'Sat',
        long: 'saturday'
    },
    sunday: {
        short: 'Sun',
        long: 'sunday'
    }
}

export const WEEKDAYS_BOOKABLE_HOURS_SKELETON = [
    {
      day: WEEK_DAYS.monday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
    {
      day: WEEK_DAYS.tuesday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
    {
      day: WEEK_DAYS.wednesday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
    {
      day: WEEK_DAYS.thursday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
    {
      day: WEEK_DAYS.friday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
    {
      day: WEEK_DAYS.saturday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
    {
      day: WEEK_DAYS.sunday.long,
      morningBookableHours: '',
      eveningBookableHours: ''
    },
];

export const EXTENSION_STATUS = {
    cancelled: 'Cancelled',
    past: 'Past',
    ongoing: 'Ongoing',
    upcoming: 'Upcoming'
}