/**
 The local state, returns the shape and initial state of the in-memory cache
*/

import {user} from './common/auth/cache';
import propertiesCache from './properties/cache';
import revenueCache from './revenue/cache';
import {uiState} from './common/ui/cache';

export const data = {
    user,
    uiState,
    ...propertiesCache,
    ...revenueCache
};