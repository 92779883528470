import {Query} from 'react-apollo';
import UI_STATE_GQL from '../ui-state.graphql';
import {getNextRenderer} from '../../utils/renderer';
import {uiState} from '../cache';
import React from "react";

/**
 HOC, wraps components that should respond to fullscreen visibility
 */
export const withFullscreen = (Component) => {
    return (props) => {
        return (
            <Query query={UI_STATE_GQL}>
                {
                    ({data: {uiState}}) => {
                        if(uiState.isFullscreenToggled)
                        {
                            return null;
                        }
                        return (
                            <Component {...props}/>
                        );
                    }
                }
            </Query>
        );
    };
};

/**
 * Toggles fullscreen visibility, receives an instance of Apollo client from the calling component
 */
export const toggleFullscreen = (client) => {
    const isFullscreenToggled = getActiveFullscreenVisibility(client);
    client.writeData({data: {uiState: {...uiState, isFullscreenToggled: !isFullscreenToggled}}});
};

/**
 * Returns current value of `isFullscreenToggled` from cache
 */
export const getActiveFullscreenVisibility = (client) => {
    const {uiState: {isFullscreenToggled}} = client.readQuery({query: UI_STATE_GQL});
    return isFullscreenToggled;
};

/**
 * Plugs into a chain of renderers (with renderNestedQueries) to notify components on change to fullscreen visibility.
 * This differs from `withFullscreen` in that components can use this to make inline alterations to UI reactively
 */
export const notifyOnFullscreenChange = (args, data, ...next) => {
    return (
        <Query query={UI_STATE_GQL}>
            {
                ({data: {uiState}}) => {
                    return (
                        getNextRenderer(args, {isFullscreenToggled: uiState.isFullscreenToggled, ...data}, ...next)
                    )
                }
            }
        </Query>
    );
};