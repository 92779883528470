/**
 User' basic profile, populated after the first sign-in
*/

export const USER_TYPE = 'UserType';

export const user = {
    __typename: USER_TYPE,
    id: null,
    email: null,
    name: null,
    roles: null,
    assignedCities: null,
    department: null,
    picture: null
};

/**
 * USER_TYPE
 */
export const getUserShape = cacheKeys => {
    return {
        data: {
            user: {
                ...cacheKeys,
                __typename: USER_TYPE //override default __typename
            }
        }
    }
};