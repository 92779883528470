import React, {useState} from "react";

/**
 Displays error alert
*/
export default ({children, withFrown=true, onHideError=()=>{}}) => {
    const [isVisible, setIsVisible] = useState(true);
    /**
     * On close
     */
    const hideError = () => {
        setIsVisible(false);
        onHideError();
    };
    return (
        <>
        {
            isVisible &&
            <div className="row">
                <div className="col">
                    <div className="alert alert-danger alert-dismissible" role="alert">
                        {children}
                        {withFrown && <span className="fa fa-frown-o ml-1"/>}
                        <button type="button" className="close" style={{border: 'none', backgroundColor: '#fae1e4'}}
                                data-dismiss="alert" aria-label="Close" onClick={hideError}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        }
        </>
    );
};