import moment from 'moment';
import { monthsMapper } from './constants';

export const CLIENT_DATE_FORMAT = 'MMMM Do YYYY';
export const CLIENT_DATETIME_FORMAT = 'MMMM Do YYYY, h:mm a';
export const DB_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:MM:SS'
/**
 * Returns date object from a string of the format YYYY-MM-DD
 */
export const getLocalDbDate = (dateStr, format = 'YYYY-MM-DD') => {
    return moment(dateStr, format).toDate();
};

/**
 * Formats `jsDate`
 * @param jsDate
 * @returns {string}
 */
export const formatLocalDate = jsDate => {
    return moment(jsDate).format('MMMM Do YYYY');
};
/**
 * Ex: Dec 10th
 */
export const formatLocalDateAbbreviated = jsDate => {
    return moment(jsDate).format('MMM Do');
};
/**
 * Ex: Tue, Dec 10th
 */
export const formatLocalDateWithDayAbbreviated = jsDate => {
    return `${moment(jsDate).format('llll').substr(0, 3)}, ${moment(jsDate).format('MMM Do')}`;
};
/**
 * Ex: April 2020
 */
export const formatYearAndMonth = jsDate => {
    return moment(jsDate).format('MMMM YYYY');
};
/**
 * Formats date object
 */
export const formatDbDate = (jsDate, format = 'YYYY-MM-DD') => {
    return moment(jsDate).format(format);
};
/**
 * Formats js Date to client DateTime format
 */
export const formatClientDateTime = jsDate => {
    return moment(jsDate).format(CLIENT_DATETIME_FORMAT);
}
/**
 * Extracts time from date, ex: 21:30
 */
export const timeFromDate = jsDate => {
    return moment(jsDate).format('HH:mm');
};
/**
 * Returns date object from given HH:MM, used mainly for date-picker
 */
export const dateFromTime = hm => {
    return moment(hm, 'HH:mm').toDate();
};
/**
 * Returns number of days between two dates
 * @param start
 * @param end
 */
export const getDaysInRange = (start, end) => {
    return moment(start).diff(moment(end), 'days');
};
/**
 * Returns day of week, THU, FRI, etc.
 */
export const getDayOfWeekThreeLetters = jsDate => {
    return moment(jsDate).format('LLLL').substr(0, 3).toUpperCase();
};
/**
 * Removes time from date, this - for instance - prevents false comparisons with check-in/check-out which are
 * date only
 * @param jsDate
 * @return {*}
 */
export const stripTime = jsDate => {
    jsDate.setHours(0);
    jsDate.setMinutes(0);
    jsDate.setSeconds(0);
    jsDate.setMilliseconds(0);
    return jsDate;
};
/**
 * Subtracts `days` from given JS date object
 */
export const subtractFromDate = (jsDate, days) => {
    return moment(jsDate).subtract(days, 'day').toDate();
};

/**
 * Checks if one date falls within range of another date
 */
export const withinRange = (startDate, endDate, rangeStartDate, rangeEndDate) => {
    return getLocalDbDate(startDate) >= getLocalDbDate(rangeStartDate) && getLocalDbDate(startDate) <= getLocalDbDate(rangeEndDate) &&
        getLocalDbDate(endDate) >= getLocalDbDate(rangeStartDate) && getLocalDbDate(endDate) <= getLocalDbDate(rangeEndDate);
};

/**
 * Gets duration between two written dates (MMMM Do YYYY) in days 
 */
export const getDurationInYearsAndMonths = (start, end, format=CLIENT_DATE_FORMAT) => {
    const startDate = moment(start, format);
    const endDate = moment(end, format);
    
    const duration = moment.duration(endDate.diff(startDate)).asYears();
    const years = Math.floor(duration);
    const months = Math.round((duration - years) * 12);

    if(duration === 1 || (years === 0 && months === 12)) {
        return '1 Year'
    }
    else if(duration < 1) {
        if(months === 0) return 'Less than a month'
        return `${months} ${months === 1 ? 'Month' : 'Months'}`;
    }
    else {
        if(months === 12) return `${years + 1} Years`
        return `${years} ${years === 1 ? 'Year' : 'Years'} ${months === 0 ? '' : `, ${months} ${months === 1 ? 'Month' : 'Months'}`}`
    }
}

/**
 * Gets duration between two written dates (MMMM Do YYYY) in days 
 */
export const getDurationInDays = (start, end, format = CLIENT_DATETIME_FORMAT) => {
    const startDate = moment(start, format);
    const endDate = moment(end, format);
    
    return moment.duration(endDate.diff(startDate)).asDays(); 
}

/**
 * Return an array of the first day of each month
 * between today and december 2019
 */
export const allTimeList = () => {
    const allTime = [];
    const now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();
    allTime.push(
        {
            label: `${monthsMapper[month]}-${year}`,
            value: `${year}-${month + 1}-01`
        }
    );

    while (allTime[allTime.length - 1]['label'] !== 'Dec-2019') {
        // if current month is jan
        if (!month) {
            month = 11;
            year = year - 1;
        } else {
            month = month - 1
        }

        allTime.push(
            {
                label: `${monthsMapper[month]}-${year}`,
                value: `${year}-${month + 1}-01`
            }
        )
    }

    return allTime;
}

/**
 * Return an array of the first day of each month
 * since the first day of last year 
 * until the first day of next year's third month
 */
export const twentySevenMonthsList = () => {
    const twentySevenMonths = [];
    const now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();
    let lastYear = year - 1;
    let nextYear = year + 1;
    twentySevenMonths.push(
        {
            label: `${monthsMapper[0]}-${lastYear}`,
            value: `${lastYear}-01-01`
        }
    );

    while (twentySevenMonths[twentySevenMonths.length - 1]['label'] !== `Mar-${nextYear}`) {
        // if current month is december
        if (month === 11) {
            month = 0;
            lastYear += 1;
        } else {
            month += 1;
        }

        twentySevenMonths.push(
            {
                label: `${monthsMapper[month]}-${lastYear}`,
                value: `${lastYear}-${month + 1}-01`
            }
        )
    }

    return twentySevenMonths;
}

export const getWeek = (weekPosition) => {
    const today = new Date()
    if(weekPosition === 'previous') {
        let firstDay = new Date(today.getFullYear(),today.getMonth(), today.getDate() - today.getDay() - 7);
        let lastDay = new Date(today.getFullYear(),today.getMonth(), firstDay.getDate() + 6);
        return {
            firstDay,
            lastDay
        }
    }
    else if(weekPosition === 'next') {
        let firstDay = new Date(today.getFullYear(),today.getMonth(), today.getDate() - today.getDay() + 7);
        let lastDay = new Date(today.getFullYear(),today.getMonth(), firstDay.getDate() + 6);
        return {
            firstDay,
            lastDay
        }
    }
    else {
        let firstDay = new Date(today.getFullYear(),today.getMonth(), today.getDate() - today.getDay());
        let lastDay = new Date(today.getFullYear(),today.getMonth(), firstDay.getDate() + 6);
        return {
            firstDay,
            lastDay
        }
    }
}

export const getMonth = (monthPosition) => {
    const today = new Date()
    if(monthPosition === 'previous') {
        let firstDay = new Date(today.getFullYear(),today.getMonth()-1, 1);
        let lastDay = new Date(today.getFullYear(),today.getMonth(), 0);
        return {
            firstDay,
            lastDay
        }
    }
    else if(monthPosition === 'next') {
        let firstDay = new Date(today.getFullYear(),today.getMonth() + 1, 1);
        let lastDay = new Date(today.getFullYear(),today.getMonth() + 2, 0);
        return {
            firstDay,
            lastDay
        }
    }
    else {
        let firstDay = new Date(today.getFullYear(),today.getMonth(), 1);
        let lastDay = new Date(today.getFullYear(),today.getMonth() + 1, 0);
        return {
            firstDay,
            lastDay
        }
    }
}

export const parseTime = (time, format = TIME_FORMAT) => {
    let formattedTime = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    let hours;
    let minutes;
    let seconds;
    if (time.length > 1) {
        formattedTime = formattedTime[0].split(':');
        hours = +formattedTime[0];
        minutes = formattedTime[1]
        seconds = formattedTime[2]
        formattedTime = `${hours % 12 || 12}${+minutes > 0 ? `:${minutes}` : ''}${+seconds > 0 ? `:${seconds}` : ''} ${hours > 12 ? 'PM' : 'AM'}`
    }
    return formattedTime;
}

export const isSame = (firstDay = moment(new Date()), secondDay, base = 'day') => {
    return moment(firstDay).isSame(secondDay, base);
}