/**
 Predefined roles associated with sections
*/

export const ROLES = {
    PROPERTIES_MENU: [
        'timeline-admin',
        'timeline-editor',
        'timeline-viewer',
        'timeline-viewer-with-prices',
        'bookings-list-admin',
        'bookings-list-editor',
        'bookings-list-viewer',
        'properties-admin',
        'properties-editor',
        'properties-viewer',
        'buildings-admin',
        'buildings-editor',
        'buildings-viewer',
        'landlords-admin',
        'landlords-editor',
        'landlords-viewer',
        'agents-admin',
        'agents-editor',
        'agents-viewer'
    ],
    MAINTENANCE_MENU: [
        'maintenance-admin',
        'maintenance-editor',
        'maintenance-viewer',
        'maintenance-notifications-editor'
    ],
    INVOICING_MENU: [
        'invoicing-admin',
        'invoicing-editor',
        'invoicing-viewer'
    ],
    CONTACTS_MENU: [
        'contacts-admin',
        'contacts-editor',
        'contacts-viewer'
    ],
    SALES_MENU: [
        'sales-admin',
        'sales-editor',
        'sales-viewer'
    ],
    ACCOUNTING_MENU: [
        'accounting-admin',
        'accounting-editor',
        'accounting-viewer'
    ],
    USTELLA_MENU: [
        'ustella-all-admin',
        'ustella-all-editor',
        'ustella-all-viewer',
        'ustella-buildings-admin',
        'ustella-buildings-editor',
        'ustella-buildings-viewer',
        'ustella-landlords-admin',
        'ustella-landlords-editor',
        'ustella-landlords-viewer',
        'ustella-properties-admin',
        'ustella-properties-editor',
        'ustella-properties-viewer',
        'ustella-tenants-admin',
        'ustella-tenants-editor',
        'ustella-tenants-viewer',
        'ustella-long-term-lease-admin',
        'ustella-long-term-lease-editor',
        'ustella-long-term-lease-viewer',
        'ustella-company-admin',
        'ustella-company-editor',
        'ustella-company-viewer',
        'ustella-commercial-lease-admin',
        'ustella-commercial-lease-editor',
        'ustella-commercial-lease-viewer',
        'ustella-maintenance-admin',
        'ustella-maintenance-editor',
        'ustella-maintenance-viewer',
        'ustella-expense-admin',
        'ustella-expense-editor',
        'ustella-expense-viewer',
        'ustella-viewing-schedule-admin',
        'ustella-viewing-schedule-editor',
        'ustella-viewing-schedule-viewer'
    ],
    SMART_LOCKS_MENU: [
        'smart-lock-admin',
        'smart-lock-editor',
        'smart-lock-viewer',
        'smart-lock-passcode-admin',
        'smart-lock-passcode-editor',
        'smart-lock-passcode-viewer'
    ],
    HOUSEKEEPING_MENU: [
        'housekeeping-admin',
        'housekeeping-editor',
        'housekeeping-viewer'
    ]
};