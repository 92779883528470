/**
 Loading indicator, styles are defined in the global index.css
*/

import React from 'react';

export default ({children}) => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-grow" data-testid="loading-spinner"/>
            {
                children && <span className="text-muted" style={{marginTop: '5px'}}>{children}</span>
            }
        </div>
    );
};