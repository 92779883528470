/**
 The top header
*/

import React from 'react';
import {Query} from 'react-apollo';
import {renderNestedQueries, getNextRenderer} from '../../utils/renderer';
import $ from 'jquery';
import USER_GQL from '../../auth/user.graphql';
import {withFullscreen} from '../fullscreen';
import Logo from '../../../theme/images/logo.png';
import DEV_ENV_ICON from '../../../theme/images/dev-env.png';
import './profile.scss';
import {signOut} from "../../utils/sign-out";

/**
 * Fetch user info from cache
 */
const queryProfile = (args, data, ...next) => {
    return (
        <Query query={USER_GQL}>
            {
                ({data: {user}}) => {
                    return (
                        getNextRenderer(args, user, ...next)
                    );
                }
            }
        </Query>
    );
};
/**
 * Build profile drop-down list
 */
const buildProfile = (args, user) => {
    /**
     * On profile click. This & profile.scss are a simple replacement of popper.js & Bootstrap.js
     * which are needed for drop-downs to function
     */
    const onProfileClick = () => {
        $('#profile-dropdown').toggleClass('st-hide').addClass('pulse');
    };
    return (
        <li className="nav-item dropdown u-pro">
            <button className="nav-link btn btn-link profile-pic" onClick={onProfileClick}>
                <img src={user.picture} alt="user"/>
                <span className="hidden-md-down">&nbsp;{user.name}&nbsp;
                    <i className="fa fa-angle-down"/>
                </span>
            </button>
            <div id="profile-dropdown" className="st-dropdown st-hide animated">
                <button onClick={signOut} className="btn btn-link dropdown-item">
                    <i className="fa fa-power-off"/> Logout
                </button>
            </div>
        </li>
    );
};

const Header = () => {
    return (
        <header className="topbar">
            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                {/* logo */}
                <div className="navbar-header" style={{textAlign: 'center', paddingLeft: '0', padding: '5px'}}>
                    <a className="navbar-brand" href="/">
                        <img src={Logo} alt="homepage"/>
                        {
                            (['localhost', 'dev.stella.stays.ai', 'dev2.stella.stays.ai'].includes(window.location.hostname)) &&
                            <img src={DEV_ENV_ICON} alt="Dev environment" style={{width: '70px'}}/>
                        }
                    </a>
                </div>
                {/* profile */}
                <div className="navbar-collapse">
                    <ul className="navbar-nav mr-auto"/>
                    <ul className="navbar-nav my-lg-0">
                        {
                            renderNestedQueries(
                                {},
                                {},
                                queryProfile,
                                buildProfile
                            )
                        }
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default withFullscreen(Header);