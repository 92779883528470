/**
Auth controller
*/

import React, {useState, useEffect} from 'react';
import {withApollo} from 'react-apollo';
import PropTypes from 'prop-types';
import {getUserShape} from './cache';
import VERIFY_TOKEN_GQL from './verify-token.graphql';
import SIGN_IN_GQL from './sign-in.graphql';
import {loadExternalScript} from '../utils/scripts-loader';
import Loading from '../ui/loading';
import Error from '../ui/error';
import './auth.scss';
import Logo from '../../theme/images/stella-large.png';

const AuthController = ({client, children}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        /**
         * Renders sign-in widget
         */
        const renderSignIn = () => {
            window.gapi.signin2.render('sign-in-button', {
                'scope': 'profile email',
                'width': 240,
                'height': 50,
                'longtitle': true,
                'theme': 'dark',
                'onsuccess': async googleUser => {
                    await signIn(googleUser.getAuthResponse().id_token, false);
                },
                'onfailure': (e) => {
                    setError('could not sign you in!');
                    console.error(e);
                }
            });
        };
        /**
         * Validates Google sign-in
         */
        const signIn = async (googleToken, renderWidget=true) => {
            let isAuthenticated = false;
            let isError = false;
            try {
                const {errors, data} = await client.mutate({
                    mutation: SIGN_IN_GQL,
                    variables: {
                        googleToken
                    },
                    errorPolicy: 'all'
                });
                if(data && data.user && !errors)
                {
                    //set token for apollo-client to pick up, see src/index.js
                    //token is cleared on sign-out
                    localStorage.setItem('token', data.user.jwtToken);
                    setIsAuthenticated(isAuthenticated = true);
                    delete data.user.jwtToken;
                    client.writeData(getUserShape({...data.user}));
                }
                isError = !!errors;
            }
            catch (e) {
                isError = true;
            }
            finally {
                setIsLoading(false);
                if(isError)
                {
                    setError('could not sign you in!');
                }
                if(!isAuthenticated)
                {
                    if(renderWidget)
                    {
                        renderSignIn();
                    }
                }
            }
        };
        /**
         * Init sign-in flow
         */
        const initGoogleSignInFlow = () => {
            loadExternalScript(
                'https://apis.google.com/js/platform.js',
                () => {
                    window.gapi.load('auth2', () => {
                        window.gapi.auth2.init({
                            client_id: process.env.REACT_APP_GOOGLE_SIGN_IN_KEY,
                            scope: 'email profile openid',
                            fetch_basic_profile: true,
                            //hosted_domain: 'stellastays.com'
                        }).then(() => {
                            if(window.gapi.auth2.getAuthInstance().isSignedIn.get())
                            {
                                //force sign-out and display sign-in dialog
                                window.gapi.auth2.getAuthInstance().then(auth => {
                                    auth.signOut().then(() => {
                                        setIsLoading(false);
                                        renderSignIn();
                                    });
                                });
                            }
                            else {
                                setIsLoading(false);
                                renderSignIn();
                            }
                        }, (e) => {
                            setError(`Access is not permitted from ${window.location.hostname}`);
                            setIsLoading(false);
                            console.error(e);
                        });
                    });
                });
        };
        /**
         * Validates our custom JWT token from local storage.
         * If this mutation fails, it means the token is expired or malformed & we then render the Google
         * sign-in dialog
         */
        const verifyToken = token => {
            return new Promise(async (resolve, reject) => {
                try {
                    const {errors, data} = await client.mutate({
                        mutation: VERIFY_TOKEN_GQL,
                        variables: {
                            token: token || ''
                        },
                        errorPolicy: 'all'
                    });
                    if(data && data.user && !errors)
                    {
                        client.writeData(getUserShape({...data.user}));
                        resolve(null);
                    }
                    else {
                        reject(null);
                    }
                }
                catch (e) {
                    reject(null);
                }
            });
        };
        const jwtToken = localStorage.getItem('token');
        if(jwtToken)
        {
            verifyToken(jwtToken).then(() => {
                setIsLoading(false);
                setIsAuthenticated(true);
            }, () => {
                //expired JWT token
                initGoogleSignInFlow();
            });
        }
        else {
            initGoogleSignInFlow();
        }
    }, [client]);
    return (
      <>
          {
              isLoading && <div style={{paddingTop: '15%'}}><Loading/></div>
          }
          {!isLoading && !isAuthenticated && <div id="auth-box" style={{backgroundImage: `url(${Logo})`}}>
              {
                  error && <Error><strong style={{fontSize: '1.1em'}}>{error}</strong></Error>
              }
              <div className="card">
                  <div className="card-header">
                      <h4>Sign in to Stays.AI</h4>
                  </div>
                  <div className="card-body justify-content-center">
                      <div id="sign-in-button"/>
                  </div>
                  <div className="card-footer">
                      <span className="text-muted">Use your g suite account to sign-in</span>
                  </div>
              </div>
          </div>}
          {
              !isLoading && isAuthenticated && children
          }
      </>
    );
};

AuthController.propTypes = PropTypes.element.isRequired;

export default withApollo(AuthController);

