/**
 Cache structure for revenue prices
 */
export const REVENUE_PRICE_TYPE = 'RevenuePriceType';

export const GOOGLE_SHEET_FIELDS = [
    'spreadSheetID',
    'sheetName',
    'dayOfYearRange',
    'basePriceRange',
    'eventRange',
    'factorRange',
    'losOverrideRange',
    'multipliersRange',
    'losMinRange',
    'minMaxRatesRange',
    'percentilesRange',
    'losPerPercentileRange'
];

export const MULTIPLIERS_FIELDS = ['m45', 'm31_45', 'm21_30', 'm16_20', 'm11_15', 'm4_10', 'm3'];

/**
 * Compound fields of MinStayDefaults & PriceDefaults GraphQL types
 */
export const DEFAULTS_FIELDS = ['minRate', 'maxRate', 'minLengthOfStay', 'discountPercentage'];
export const MIN_STAY_PERCENTILE_FIELDS = ['p9Actual', 'p75Actual', 'p5Actual', 'p25Actual', 'p15Actual', 'p9LOS', 'p75LOS', 'p5LOS', 'p25LOS', 'p15LOS'];

export const revenuePrice = {
    __typename: REVENUE_PRICE_TYPE,
    /**
     * ID of selected property in the wizard, selection is restored when navigating back to the grid
     */
    activePropertyID: null,
    activeYear: null
};

/**
 * REVENUE_PRICE_TYPE
 */
export const getPriceCacheShape = cacheKeys => {
    return {
        data: {
            revenuePrice: {
                __typename: REVENUE_PRICE_TYPE,
                ...cacheKeys
            }
        }
    };
};