/**
 * Ends the Google sign-in session
 */

export const signOut = () => {
    const _onSignOut = () => {
        localStorage.clear();
        window.location.reload();
    };
    //we could have logged the user-in based on the custom JWT token in localStorage without
    // loading Google sign-in, see auth/controller
    if(window.gapi && window.gapi.auth2 && window.gapi.auth2.getAuthInstance())
    {
        window.gapi.auth2.getAuthInstance().then(auth => {
            auth.signOut().then(() => {
                _onSignOut();
            });
        })
    }
    else {
        _onSignOut();
    }
};