/**
 * Loads external scripts synchronously
 * @param url
 * @param onLoad
 */
export const loadExternalScript = (url, onLoad) => {
    const script = document.createElement('script');
    script.setAttribute('src', url);
    document.body.appendChild(script);
    script.onload = onLoad;
};