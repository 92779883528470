/**
 Controls rendering of components based on assigned roles
*/

import USER_GQL from '../user.graphql';

export const checkAccessGuard = (client, allowAnyOfRoles, isLiteral = false, disallowAnyOfRoles) => {
    let {user: {roles}} = client.readQuery({query: USER_GQL});
    roles = roles || [];
    for(let role of roles)
    {
        if(allowAnyOfRoles?.includes(role))
        {
            return true;
        }

        if(disallowAnyOfRoles?.includes(role))
        {
            return false;
        }
    }
    return !isLiteral && (roles.includes('manager') || roles.includes('developer'));
};