import React, { createContext } from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import omitDeep from 'omit-deep';
import typeDefs from '../typedefs';

export const KnowledgeBaseApolloClientContext = createContext();

export const KnowledgeBaseApolloClientProvider = (props) => {
    /**
         * Initialize a second Apollo client
         * @type {ApolloClient<NormalizedCacheObject>}
         */
    const knowledgeBaseLinkOptions = {
        uri: process.env.REACT_APP_KNOWLEDGE_BASE_GRAPHQL_ENDPOINT_URI.replace('%host', window.location.hostname),
        fetch: (uri, options) => {
            options.headers['x-token'] = localStorage.getItem('token');
            return fetch(uri, options);
        }
    };

    /**
     * Locally injected __typename by InMemoryCache causes backend-exceptions when mutations are submitted.
     * This middleware intercepts all requests (Query & Mutations) & strips out __typename.
     * solution thanks to https://librenepal.com/article/remove-typenames-in-apollo-graphql/
     * more here: https://www.apollographql.com/docs/link/links/http/
     * @type {ApolloLink}
     */
    const apolloMiddleware = new ApolloLink((operation, forward) => {
        if (operation.variables && !operation.variables.file) {
            operation.variables = omitDeep(operation.variables, '__typename');
        }
        return forward(operation);
    });

    const knowledgeBaseClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: apolloMiddleware.concat(createUploadLink(knowledgeBaseLinkOptions)),
        resolvers: {},
        typeDefs
    });

    return (
        <KnowledgeBaseApolloClientContext.Provider value={knowledgeBaseClient}>
            {props.children}
        </KnowledgeBaseApolloClientContext.Provider>
    );
};