import React from 'react';
import {withFullscreen} from '../fullscreen';

const Footer = () => {
    return (
        <footer className="footer">
            © {new Date().getFullYear()} StellaStays
        </footer>
    );
};

export default withFullscreen(Footer);