/**
 Shape & initial state of the properties list + filter cache
 */
const PROPERTIES_FILTER_TYPE = 'PropertiesFilter';
const TIMELINE_PROPERTIES_FILTER_TYPE = 'TimelinePropertiesFilter';
const PROPERTIES_FILTER_ROOMS_BEDS = 'PropertiesFilterForRoomsAndBeds';
const PROPERTIES_FILTER_AVAILABILITY = 'PropertiesFilterForAvailability';
const PROPERTIES_ORDER_BY_TYPE = 'PropertiesOrderBy';
//when a nested component (i.e: modal) does not have access to <Route/>
const REDIRECT_TIMELINE_TO_ROUTE = 'RedirectTimelineToRoute';

export const propertiesFilter = {
    __typename: PROPERTIES_FILTER_TYPE,
    search: null,
    /**
     * State behind the dropdown filter
     */
    roomsBeds: {
        __typename: PROPERTIES_FILTER_ROOMS_BEDS,
        bedrooms: 0,
        beds: 0,
        baths: 0
    },
    /**
     * Filters by amenities, empty array cancels this filter
     */
    amenities: [],
    /**
     * Filters by type of property, empty array cancels this filter
     */
    propertiesTypes: [],
    city: null, //default value is set on login
    neighbourhood: null,
    buildingID: null,
    /**
     * Custom order by
     */
    orderBy: {
        __typename: PROPERTIES_ORDER_BY_TYPE,
        bedrooms: null,
        cityName: null,
        buildingName: null
    },
    /**
     * extended columns are listingName, max guests, etc. excluding performance
     */
    showExtendedColumns: false,
    /**
     * occupancy rate & vacancy days
     */
    showPerformanceColumns: false,
    dropDownFilterSubmissionSignal: 0
};

export const timelinePropertiesFilter = {
    __typename: TIMELINE_PROPERTIES_FILTER_TYPE,
    availability: {
        __typename: PROPERTIES_FILTER_AVAILABILITY,
        startDate: null,
        endDate: null,
        alternativeBookedRatio: null
    },
    roomsBeds: {
        __typename: PROPERTIES_FILTER_ROOMS_BEDS,
        bedrooms: 0,
        beds: 0,
        baths: 0
    },
    // Filters by amenities, empty array cancels this filter
    amenities: [],
    // Filters by type of property, empty array cancels this filter
    propertiesTypes: [],
    neighbourhood: null,
    buildingID: null,
    unitHasKitchen: null
};

export const redirectTimelineToRoute = {
    __typename: REDIRECT_TIMELINE_TO_ROUTE,
    url: null
};

/**
 * PROPERTIES_FILTER_TYPE
 */
export const getPropertiesFilterShape = cacheKeys => {
    return {
        data: {
            propertiesFilter: {
                __typename: PROPERTIES_FILTER_TYPE,
                ...cacheKeys
            }
        }
    }
};

/**
 * TIMELINE_PROPERTIES_FILTER_TYPE
 */
export const getTimelinePropertiesFilterShape = cacheKeys => {
    return {
        data: {
            timelinePropertiesFilter: {
                __typename: TIMELINE_PROPERTIES_FILTER_TYPE,
                ...cacheKeys
            }
        }
    }
};

/**
 * PROPERTIES_FILTER_ROOMS_BEDS
 */
export const getRoomsAndBedsFilterShape = cacheKeys => {
    return getPropertiesFilterShape(
        {
            roomsBeds: {
                __typename: PROPERTIES_FILTER_ROOMS_BEDS,
                ...cacheKeys
            }
        }
    );
};

/**
 * PROPERTIES_ORDER_BY_TYPE
 */
export const getOrderByShape = cacheKeys => {
    return getPropertiesFilterShape(
        {
            orderBy: {
                __typename: PROPERTIES_ORDER_BY_TYPE,
                ...cacheKeys
            }
        }
    );
};

/**
 REDIRECT_TIMELINE_TO_ROUTE
 */
export const getRedirectTimelineToRouteShape = cacheKeys => {
    return {
        data: {
            redirectTimelineToRoute: {
                __typename: REDIRECT_TIMELINE_TO_ROUTE,
                ...cacheKeys
            }
        }
    }
};