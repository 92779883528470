import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloClient} from 'apollo-client';
import {ApolloProvider} from 'react-apollo';
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloLink} from 'apollo-link';
import {createUploadLink} from 'apollo-upload-client';
import AuthController from './common/auth/controller';
import {data} from './cache';
import omitDeep from 'omit-deep';
import typeDefs from './typedefs';
import App from './app';
import './theme/index.scss';
import * as serviceWorker from './serviceWorker';

/**
 * Initialize Apollo client
 * @type {ApolloClient<NormalizedCacheObject>}
 */
const linkOptions = {
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI.replace('%host', window.location.hostname),
    fetch: (uri, options) => {
        options.headers['x-token'] = localStorage.getItem('token');
        return fetch(uri, options);
    }
};
/**
 * Locally injected __typename by InMemoryCache causes backend-exceptions when mutations are submitted.
 * This middleware intercepts all requests (Query & Mutations) & strips out __typename.
 * solution thanks to https://librenepal.com/article/remove-typenames-in-apollo-graphql/
 * more here: https://www.apollographql.com/docs/link/links/http/
 * @type {ApolloLink}
 */
const apolloMiddleware = new ApolloLink((operation, forward)=>{
    if (operation.variables && !operation.variables.file) {
        operation.variables = omitDeep(operation.variables, '__typename');
    }
    return forward(operation);
});
export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: apolloMiddleware.concat(createUploadLink(linkOptions)),
    resolvers: {},
    typeDefs
});

/**
 * Setup initial cache state
 */
client.cache.writeData({data});

ReactDOM.render(
    <ApolloProvider client={client}>
        <AuthController>
            <App/>
        </AuthController>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
